import { buyCard } from '../api/card'

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async buyCard (_, id) {
      const res = await buyCard(id).catch(err => err)
      return res
    }
  }
}