import Vue from 'vue'
import { Toast } from 'vant';
Vue.use(Toast);
// url中截取指定参数
export function getParam (item, url) {
  var sValue = url.match(new RegExp("[?&]" + item + "=([^&]*)(&?)", "i"));
  return sValue ? sValue[1] : sValue;
}

// 手机号码正则
export function mobileReg(val) {
  const reg = /^1[3456789]\d{9}$/
  return reg.test(val)
}
