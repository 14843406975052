<!--
 * @Author: 月魂
 * @Date: 2021-03-22 17:06:07
 * @LastEditTime: 2021-04-27 17:44:53
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\App.vue
-->
<template>
  <div class="body">
    <Loading v-show="LOADING" />
    <router-view/>
  </div>
</template>

<script>
import Loading from '@/views/Loading'
import { mapGetters  } from 'vuex'
export default {
  name: 'App',
  components: {
    Loading
  },
  computed: {
    ...mapGetters(['LOADING'])
  }
}
</script>

<style>
html, body {
  height: 100%;
}
.body {
  height: 100%;
}
.cont {
  padding: 10px;
}
</style>
