import request from '../utils/request'
import qs from 'qs'

export function buyCard(id) {
  return request({
    url: `/v1/wechat/pay/${id}`,
    method: 'post'
  })
}

export function getCardList(params) {
  return request({
    url: `/v1/card/categories?${qs.stringify(params)}`,
    method: 'get'
  })
}

export function getCardDetail(id) {
  return request({
    url: `/v1/card/categories/${id}`,
    method: 'get'
  })
}

export function getCardBag(params) {
  return request({
    url: `/v1/card/cards?origin=2&&${qs.stringify(params)}`,
    method: 'get'
  })
}

// 获取卡号核销码
export function getCardCode(id) {
  return request({
    url: `/v1/card/cards/${id}/random-code`,
    method: 'get'
  })
}

// 获取微信分享所需参数
export function getWxParams (link) {
  return request({
    url: `/v1/wechat/pay/sign?url=${link}`,
    method: 'get'
  })
}
