import { getToken, getUserInfo } from '../api/user'

export default {
  namespaced: true,
  state: {
    avatar: '', // 一刷新就没了保存个鸡儿
    nickName: ''
  },
  mutations: {
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_NICKNAME: (state, nickName) => {
      state.nickName = nickName;
    }
  },
  actions: {
    async getToken (_, code) {
      const res = await getToken(code).catch(err => err)

      localStorage.setItem('token', res.data.token);
      return res;
    },
    async getUserInfo ({ commit }, code) {
      // 此处需查询用户手机号
      const res = await getUserInfo(code).catch(err => err)
      if (res.data.nick_name && res.data.head_url) {
        commit('SET_AVATAR', res.data.head_url);
        commit('SET_NICKNAME', res.data.nick_name);
        const params = {
          avatar: res.data.head_url,
          nickName: res.data.nick_name
        }
        if (res.data.mobile) {
          params.mobile = res.data.mobile
        }
        localStorage.setItem('userInfo', JSON.stringify(params))
      }
      return res;
    },

    // getToken ({ commit }, code) {
    //   return getToken(code).then(res => {
    //     console.log(res);
    //     const { data } = res;
    //     commit('SET_AVATAR', data.avatar);
    //     commit('SET_NICKNAME', data.nickName);
    //     localStorage.setItem('token', data.token);
    //     return res;
    //   }).catch(error => error)
    // }
  }
}