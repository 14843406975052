import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import user from './user'
import card from './card'
import loading from './loading'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    card,
    loading
  },
  getters
})
