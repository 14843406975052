import request from '@/utils/request'
import qs from 'qs'

export function getToken(code) {
  return request({
    url: `/v1/oauth/wechat-mp?code=${code}`,
    method: 'post',
  });
}

export function getUserInfo(code) {
  return request({
    url: `/v1/oauth/wechat-mp/sns/userinfo?code=${code}`,
    method: 'post',
  });
}

export function getCode(mobile) {
  return request({
    url: `/v1/common/sms/token?mobile=${mobile}`,
    method: 'post'
  })
}

export function validateCode(params) {
  return request({
    url: `/v1/common/sms/token/validate?${qs.stringify(params)}`,
    method: 'post'
  })
}
