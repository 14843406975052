import Vue from 'vue';
import axios from 'axios'
import router from '../router'
import store from '../store/index'
import { Toast } from 'vant'

Vue.use(Toast);

/* 
  提示函数
  禁止点击蒙层，显示一秒后关闭
*/
const tip = msg => {
  Toast({
    message: msg,
    duration: 1000,
    forbidClick: true,
  });
}

/* 
  跳转登录页
  携带当前页面路由，以期在登录页面完成登录后返回当前页面
*/
const toLogin = () => {
  if (window.location.hash === '#/') {
    window.location.reload()
  } else {
    router.replace('/');
  }
  // router.replace({
  //   path: '/login',
  //   query: router.currentRoute.fullPath,
  // });
}

/* 
  请求失败后的错误统一处理
  status 请求失败的状态码
*/
const errorHandle = (code, other) => {
  // 状态码判断
  switch (code) {
    // 401: 未登录状态，跳转登录页
    case 401:
      toLogin()
      break;
    // 403: token过期
    // 清除token并跳转登录页
    case 403:
      // 此处token失效则重新进行授权
      // tip('登录过期，请重新登陆')
      localStorage.removeItem('token')
      // store.commit('loginSuccess', null)
      setTimeout(() => {
        toLogin()
      }, 1000)
      break;
    // 404: 请求不存在
    case 404:
      tip('请求的资源不存在')
      router.replace('/404')
      break;
      case 500:
      tip('服务器错误')
      router.replace('/500')
        break;
    default:
      console.log(other, 'request error and params is other');
  }
}

// 创建axios实例
let service = axios.create({
  timeout: 1000 * 60,
})

// 设置post请求头
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

/* 
  请求拦截器
  每次请求前，如果存在token则在请求头中携带token
*/
service.interceptors.request.use(
  config => {
    store.commit('loading/SHOW_LOADING')
    // 根据本地是否存在token判断用户的登录情况（微信是否授权登录）
    const token = localStorage.getItem('token') // token也可以放在localStorage里面进行获取
    token && (config.headers.Authorization = `Bearer ${token}`);
    return config;
  },
  error => {
    store.commit('loading/HIDE_LOADING')
    Promise.error(error)
  }
)

/* 
  响应拦截器
*/
service.interceptors.response.use(
  res => {
    store.commit('loading/HIDE_LOADING')
    // 此处对status进行处理，只要接收到响应就算请求成功，无论status是多少
    if (res.status >= 200 && res.status < 300) {
      if (res.data.code !== 0) {
        tip(res.data.msg)
      }
      return Promise.resolve(res.data)
    } else {
      errorHandle(res.data.code, res.data.msg);
      return Promise.reject(res);
    }
    // res.status === 200 ? Promise.resolve(res) : Promise.reject(res)
  },
  error => {
    store.commit('loading/HIDE_LOADING')
    // 以下为对错误的详细处理
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx范围内
      errorHandle(response.status, response.data.message);
      // tip(error.message);
      return Promise.reject(response);
    }
    // else {
    //   // 如果需要处理断网的情况，则需要以下代码
    //   // 请求超时或断网时，更新state的network状态
    //   // network状态在app.vue中控制着一个全局的断网提示组件的显示与隐藏
    //   if (!window.navigator.onLine) {
    //     store.commit('changeNetwork', false);
    //   } else {
    //     return Promise.reject(error);
    //   }
    // }
  }
)

export default service;