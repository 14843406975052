<!--
 * @Author: 月魂
 * @Date: 2021-04-15 09:29:43
 * @LastEditTime: 2021-04-15 09:45:09
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\Loading.vue
-->
<template>
  <div class="loading">
    <van-loading color="#1989fa" size="48" />
  </div>
</template>

<script>
import Vue from 'vue'
import { Loading } from 'vant'
Vue.use(Loading)

export default {
  name: 'Loading'
}
</script>

<style lang="less" scoped>
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
</style>
