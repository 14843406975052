import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { getParam } from '../utils/index'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'Ticket',
  //   component: () => import('../views/Ticket.vue')
  // },
  {
    path: '/',
    name: 'Card',
    component: () => import('../views/Card.vue'),
  },
  // {
  //   path: '/brand',
  //   name: 'Brand',
  //   component: () => import('../views/Brand.vue')
  // },
  {
    path: '/my',
    name: 'My',
    component: () => import('../views/My.vue')
  },
  {
    path: '/card/detail/:id',
    name: 'CardDetail',
    component: () => import('../views/CardDetail.vue')
  },
  {
    path: '/ticket/detail/:id',
    name: 'TicketDetail',
    component: () => import('../views/TicketDetail.vue')
  },
  {
    path: '/card/order/:id',
    name: 'CardOrder',
    component: () => import('../views/CardOrder.vue')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('../views/Personal.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Order.vue')
  },
  {
    path: '/ticketHolder',
    name: 'TicketHolder',
    component: () => import('../views/TicketHolder.vue')
  },
  {
    path: '/cardBag',
    name: 'CardBag',
    component: () => import('../views/CardBag.vue')
  },
  {
    path: '/couponHolder',
    name: 'CouponHolder',
    component: () => import('../views/CouponHolder.vue')
  },
  {
    path: '/card/order/detail/:id',
    name: 'CardOrderDetail',
    component: () => import('../views/CardOrderDetail.vue')
  },
  {
    path: '/pay/:id',
    name: 'Pay',
    component: () => import('../views/pay.vue')
  },
  {
    path: '/contract',
    name: 'Contract',
    component: () => import('../views/Contract.vue')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/Mobile.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../views/500.vue')
  }
]

const router = new VueRouter({
  routes
})

// 导航守卫
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('token')
  // let appid = 'wx349a282b122e7e2a' // 开发(云通文投)
  let appid = 'wx4383e2b399a1fa87' // 生产(儿戏)
  if (!token) {
    let code = getParam('code', window.location.href);
    if (!code) {
      // 执行授权操作
      // let redirectUrl = 'https://api.sxqinwen.com/#/'; // 开发
      let redirectUrl = 'https://ex.topartsintermational.com/#/' // 生产
      window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(redirectUrl)}&response_type=code&scope=snsapi_base#wechat_redirect`)
    } else {
      // 执行获取token操作
      store.dispatch('user/getToken', code).then(() => {
        const { origin } = window.location
        window.location.replace(`${origin}/#/`)
      });
    }
  } else {
    next()
  }
})

export default router
